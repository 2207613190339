<template>
  <div>
    <div class="row">
      <div class="col-lg-8">
        <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog blog-detail">
          <div class="iq-card-body">
              <div class="image-block" v-if="eventDetail.event_img">
                  <img :src="eventDetail.event_img" class="img-fluid rounded w-100">
              </div>
              <div class="image-block" style="height: 460px;" v-else>
                <div style="background-color: #EEEEEE; width: 100%; height: 100%; border-radius: 5px; display: flex; text-align: center;">
                  <span style="margin: auto; font-size: 110px;">{{ eventDetail && eventDetail.event_name ? eventDetail.event_name.slice(0,1) : "" }}</span>
                </div>
              </div>
              <div class="blog-description mt-2">
                  <b-badge class="border mr-2 mb-2 font-weight-normal" variant="primary"
                      style="font-size: 11px; font-weight: bold !important;"> {{eventDetail.event_master_type}}
                  </b-badge>
                  <div class="register">
                  <h5 class="mb-3 pb-4 border-bottom">
                      {{eventDetail.event_name}}
                        <b-btn v-if="eventDetail.event_finished" class="pull-right mt-1" variant="primary" @click="openUrlInNewTab(`/event/${eventDetail.event_id}`)">
                        Register
                      </b-btn>
                  </h5>
                </div>
                  <div class="row blog-meta align-items-center mb-3">
                      <div class="col-sm-12 col-md-4 col-lg-4 date ">
                          <i class="ri-calendar-2-fill text-primary pr-2"> </i>{{eventDetail.event_start_ts | dateFormatDDMMYYYYHHMMA}}
                      </div>
                      <div class="col-sm-12 col-md-4 col-lg-4 ">
                          <i class="fa fa-clock-o pr-2 text-primary" aria-hidden="true"></i> Duration:<span> {{eventDetail.event_duration | durationToDHM}}</span>
                      </div>
                      <div class="col-sm-12 col-md-4 col-lg-4 rating_comments">
                        <div v-for="index in 5" :key="index" class="rating ratingInput">
                          <label v-if="index <= eventDetail.rating" class="selected">☆</label>
                          <label v-else class="unselected">☆</label>
                        </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-xl-1 col-6">
                          <span class="mr-2 w-100px">Activity Type:</span>
                          <b-badge class="border ml-2 mr-2 mb-2 font-weight-normal" variant="none">
                              <span>{{eventDetail.event_type_name}}</span>
                          </b-badge>
                      </div>
                      <div class="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-xl-1 col-6">
                          <span class="w-100px">
                              Activity Level:
                          </span>
                          <b-badge class="border ml-2 mr-2 mb-2 font-weight-normal" variant="none">
                              <span> {{eventDetail.event_lev_name}} </span>
                          </b-badge>
                      </div>
                  </div>
                  <p>
                    <template v-if="eventDetail.duration_left_start_meeting">
                      <span v-if="!eventDetail.duration_left_start_meeting.includes('-')">
                        [ Meeting will start in {{eventDetail.duration_left_start_meeting}} ]
                      </span>
                      <span v-if="eventDetail.duration_left_start_meeting.includes('-') && !eventDetail.duration_left_end_meeting.includes('-')">
                        [ Meeting will end in {{eventDetail.duration_left_end_meeting}} ]
                      </span>
                    </template>
                  </p>
                  <p class="vhtmlTag vueHtmlParent" v-html="eventDetail.event_desc">
                  </p>
                  <p>
                  </p>
                  <div v-if="eventDetail.event_interests && eventDetail.event_interests.length > 0">
                  <b-badge class="border ml-2 mr-2 mb-2 font-weight-normal" variant="primary" v-for="(item, index) of eventDetail.event_interests" :key="index+'event'">
                    {{item.interest_name}}
                  </b-badge>
              </div>
              </div>
          </div>
          <template v-if="eventDetail.event_mentions && eventDetail.event_mentions.length">
          <div class="blog user-comment">
              <div class="iq-card-header d-flex justify-content-between">
              <div class="header-title">
                  <h4 class="iq-card-title">
                  In Association With
                  </h4>
              </div>
              </div>
              <div class="iq-card-body shadow">
              <div class="row">
                  <div class="col-12 col-sm-6 col-lg-4" v-for="mention in eventDetail.event_mentions" :key="mention.id">
                  <div class="blog" style="cursor: pointer;">
                      <div class="iq-card-body pt-0">
                      <div class="d-flex align-items-center">
                          <div class="user-image mb-3">
                          <img class="avatar-80 rounded" v-if="mention.org_profile_img || mention.club_profile_img" :src="mention.org_profile_img ? mention.org_profile_img : mention.club_profile_img" alt="#" data-original-title="" title="">
                          <b-avatar v-else class="avatar-80 rounded">
                              {{ mention && mention.org_name ? mention.org_name.slice(0, 1) : mention.club_name ? mention.club_name.slice(0, 1) : "E"}}
                          </b-avatar>
                          </div>
                          <div class="ml-3">
                          <h5>
                              {{ mention && mention.org_name ? mention.org_name : mention.club_name ? mention.club_name : "Coming Soon"}}
                          </h5>
                          <p>
                              {{ mention && mention.org_id ? "Organisation" : mention.club_id ? "Club" : "" }}
                          </p>
                          </div>
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
              </div>
          </div>
          </template>
        </div>
      </div>
      <div class="col-lg-4">
          <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog-post">
              <div class="iq-card-header d-flex justify-content-between">
                  <div class="header-title">
                      <h4 class="iq-card-title">
                          Upcoming events
                      </h4>
                  </div>
              </div>
              <div class="iq-card-body right_side_bar">
                  <ul class="row list-inline p-0 mb-0 ">
                      <li class=" mb-2 col-12 col-xs-6 col-sm-6 col-md-6 col-lg-12" v-for="(newEvent, index) of vmEventlist" :key="index">
                        <div class="row align-items-top pb-1 border-bottom pointer" @click="openUrlInNewTab(PATH+newEvent.event_id)">
                              <div class="col-sm-12 col-md-5 col-lg-5">
                                  <div class="image-block" style="height: 80px;" v-if="newEvent.event_img">
                                      <img :src="newEvent.event_img" class="img-fluid rounded w-100" style="height: 80px;" alt="blog-img">
                                  </div>
                                  <div v-else style="background-color: #EEEEEE; width: 100%; height: 80px; border-radius: 5px; display: flex; text-align: center;">
                                      <span style="margin: auto; font-size: 36px;">{{ newEvent.event_name.slice(0, 1) }}</span>
                                  </div>
                              </div>
                              <div class="col-sm-12 col-md-7 col-lg-7">
                                  <div class="blog-description pl-2">
                                    <h6 class="mb-0">{{ newEvent.event_name }}</h6>
                                    <div class="date">
                                      <a style="cursor: pointer;" tabindex="-1">
                                        {{ newEvent.event_start_ts | dateFormatDDMMYYYYHHMMA }}
                                      </a>
                                    </div>
                                  </div>
                              </div>
                          </div>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { socialvue } from "../../config/pluginInit"
import { events } from "../../FackApi/api/events.js"
import Utility from "../../Utils/utility.js"

export default {
  name: "GideEventView",
  components: {
  },
  data () {
    return {
      eventId: null,
      eventDetail: {},
      vmEventlist: [],
      PATH: "/GideEventV1" // Webflow Route
    }
  },
  mounted () {
    socialvue.index()

    if (this.$route.path.includes(this.PATH)) {
      this.PATH = "/GideEventV1/" // Webflow Route
    }
    else {
      this.PATH = "/public/GideEvent/" // Public Route with Nav Header
    }

    this.eventId = this.$route.params.eventId
    this.getEventDetail()
    this.getEventList()
  },
  methods: {
    /**
     * openUrlInNewTab
     */
    openUrlInNewTab (url) {
      window.open(url, "_blank")
    },
    /**
     * getEventDetail
    */
    async getEventDetail () {
      try {
        const eventDetailResp = await events.eventViewPublic(this, this.eventId)
        if (eventDetailResp.resp_status) {
          this.eventDetail = eventDetailResp.resp_data.data
          this.eventDetail.duration_left_start_meeting = Utility.getDiffDateTime(new Date(), this.eventDetail.event_start_date)
          this.eventDetail.duration_left_end_meeting = Utility.getDiffDateTime(new Date(), this.eventDetail.event_end_date)
        }
      }
      catch (error) {
        console.error("Exception occurred at eventView() and Exception:", error.message)
      }
    },
    /**
     * getEventList
    */
    async getEventList () {
      try {
        const filter = {
          limit: 10,
          upcoming_events: true
        }

        if (this.$route.meta.name === "GideUniEvent") {
          filter.user_role = "USERROLE11115"
        }

        const eventResp = await events.eventListPublic(this, filter)
        if (eventResp.resp_status) {
          this.vmEventlist = eventResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at eventList() and Exception:", err.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-device-width: 320px) and (max-device-width: 576px) {
    .image-block{
        width:100%;
    }
}
.iq-card-body .right_side_bar{
    padding: 0px 15px;
}
@media screen and (min-width: 714px) {
    .register .border-bottom{
    line-height: 45px;
    padding-bottom: 10px !important;
  }
}
@media screen and (max-width:415px) {
  .register .border-bottom{
    height: 80px !important;
  }
}
h4.iq-card-title{
  font-size:18px;
}
</style>
